import axios from '@/libs/axios';

export default {
  /**
   * Send request for get comments groups
   * @returns {Object}
   */
  async getComments(id, query = {}) {
    const res = await axios.get(`/comments/${id}`, { params: query });
    return res;
  },

  /**
   * Send request for add comment to group
   * @returns {Object}
   */
  async addComment(id, data) {
    const res = await axios.post(`/comments/${id}`, data);
    return res;
  },

  /**
   * Send request for add comment to group
   * @returns {Object}
   */
  async deleteComment(id) {
    const res = await axios.delete(`/comments/${id}`);
    return res;
  },
};
