<template>
  <div class="user-post__area" style="border: none;">
    <div id="header_post_form" class="user-post__item">
      <Alert :variant="variant" v-if="text">{{ text }}</Alert>
      <div class="user-post__body">
        <textarea
          v-model="form.text"
          cols="30"
          rows="7"
          placeholder="Insert your short text here"
          maxlength="500"
        ></textarea>
        <div class="counter__box" style="margin-bottom: 20px;">
          <span style="color: red; margin: 0 5px;">*</span>
          left characters:
          <span class="counter">{{500 - form.text.length}}</span>
        </div>
        <quill-editor
          ref="myQuillEditor"
          v-model="form.text_full"
          :options="editorOption"
        />
        <hr class="divider-light">
        <div class="addmedia">
          <div class="addmedia__title">
            Add media
          </div>
          <ul class="addmedia__list">
            <li>
              <span
                style="cursor:pointer;"
                title="Attach PDF"
                @click="showInputPDF = !showInputPDF"
              >
                <i class="icon-file"></i>
              </span>
            </li>
            <li>
              <span
                style="cursor:pointer;"
                title="Attach Video"
                @click="showInputVideo = !showInputVideo"
              ><i class="icon-youtube"></i></span>
            </li>
            <li>
              <span
                style="cursor:pointer;"
                title="Attach Image"
                @click="showInputImage = !showInputImage"
              >
                <i class="icon-image"></i>
              </span>
            </li>
          </ul>
          <div v-if="showInputVideo" class="addmedia__wrapper">
            <h5 class="addmedia__wrapper-title">Add a Video</h5>
            <span class="addmedia__wrapper-notice">
              Here you can insert a URL from a video platform (for example YouTube).
              The video will then be embedded.
            </span>
            <input
              v-model="selectedVideo"
              type="text"
              name="url"
              pattern="^https?://.+$"
              placeholder="Insert URL"
              class="form-control"
            >
            <span v-if="isBadVideoLink" class="error_text">Bad link</span>
            <span class="btn btn-primary" @click="uploadVideo">Add video</span>
          </div>
          <div v-if="showInputPDF || showInputImage" class="addmedia__wrapper">
            <h5 class="addmedia__wrapper-title">Add a File</h5>
            <span class="addmedia__wrapper-notice">
              {{textInputFile}}
            </span>
            <div data-v-0bfbcf36="" class="file-drop">
              <div data-v-0bfbcf36="" class="file-drop__body">
                <input
                  type="file"
                  :accept="fileType"
                  @change="onChangeFile"
                  ref="inputFiles"
                >
                <img data-v-0bfbcf36="" src="images/upload.png" alt="" class="file-drop__icon">
                <div data-v-0bfbcf36="" class="file-drop__info">
                  Formats: {{fileFormat}}<br data-v-0bfbcf36=""> Max: 10Mb
                </div>
              </div>
            </div>
            <span
              v-if="isBadFile"
              class="error_text"
            >Bad file type or size (Size must be max 10Mb).</span>
            <span
              class="btn btn-primary"
              :disabled="isBadFile"
              @click="setFile"
            >Add file</span>
          </div>
          <div v-if="form.pdf || form.picture || form.video" class="addmedia__downloaded">
            <h5 class="addmedia__downloaded-title">Uploaded files</h5>
            <div v-if="form.pdf" class="addmedia__downloaded-item">
              <span class="icon-pdf"></span>
              <span class="addmedia__downloaded-name">
                {{form.pdf}}
              </span>
              <span class="icon-delete" @click="onRemoveFile('pdf')"></span>
            </div>
            <div v-if="form.picture" class="addmedia__downloaded-item">
              <span class="icon-image2"></span>
              <span class="addmedia__downloaded-name">
                {{form.picture}}
              </span>
              <span class="icon-delete" @click="onRemoveFile('picture')"></span>
            </div>
            <div v-if="form.video" class="addmedia__downloaded-item">
              <span class="icon-video"></span>
              <span class="addmedia__downloaded-name">
                {{form.video}}
              </span>
              <span class="icon-delete" @click="onRemoveVideo"></span>
            </div>
          </div>
        </div>

        <hr class="divider-light">
        <div class="user-post__actions">
          <div class="user-post__actions-switch">
            <label class="switch">
              <input v-model="isParentStrategyProject" type="checkbox">
              <span class="s-slider round"></span>
            </label>
            <div>
              <h6>Attach Strategy Project</h6>
            </div>
          </div>
          <div style="display: flex;">
            <span
              class="btn btn-primary btn_cancel"
              @click="onCancelPost"
              style="margin-right: 10px"
            >Cancel</span>
            <span
              class="btn btn-primary"
              style="display: flex; align-items: center;"
              :disabled="isLoadingFile"
              @click="createPost"
            >
              <span v-if="isLoadingFile" class="spinner"
                :style="{
                  'background-image': 'url(' + require('@/assets/images/spinner-icon.png') + ')'
                }"
              ></span>
              Post</span>
          </div>
        </div>
        <template v-if="isParentStrategyProject">
          <div class="search__category" style="margin-bottom: 15px;">
            <multiselect
              class="select_category select_project"
              v-model="selectedProject"
              :options="projects"
              select-label=""
              selected-label=""
              deselectLabel=""
              :close-on-select="true"
              placeholder=""
              label="name"
              track-by="id"
            >
              <template slot="selection" slot-scope="{ values, search, isOpen }">
                <span class="multiselect__single" v-if="values.length && !isOpen">
                  {{ values.length }} selected
                </span>
              </template>
            </multiselect>
          </div>
        </template>
        <hr class="divider-light">
        <div class="choose">
          <div>
            <h5 class="choose__title">
              Choose post type:<span style="color: red; margin: 0 5px;">*</span>
            </h5>
            <div class="search__category" style="margin-bottom: 15px;">
              <multiselect
                class="select_category"
                v-model="selectedPostType"
                :options="postTypes"
                select-label=""
                selected-label=""
                deselectLabel=""
                :close-on-select="true"
                label="name"
                track-by="id"
                placeholder="Select Type"
              >
              </multiselect>
            </div>
          </div>
          <div>
            <h5 class="choose__title">
              Choose a channel:<span style="color: red; margin: 0 5px;">*</span>
            </h5>
            <div class="search__category" style="margin-bottom: 15px;">
              <multiselect
                class="select_category"
                v-model="selectedCategory"
                :options="categories"
                select-label=""
                selected-label=""
                deselectLabel=""
                :close-on-select="true"
                placeholder="Select Channel"
                label="name"
                track-by="id"
              >
                <template slot="selection" slot-scope="{ values, search, isOpen }">
                  <span class="multiselect__single" v-if="values.length && !isOpen">
                    {{ values.length }} selected
                  </span>
                </template>
              </multiselect>
            </div>
          </div>
          <div>
            <h5 class="choose__title">Choose a subfilter: </h5>
            <div class="search__category" style="margin-bottom: 15px;">
              <multiselect
                class="select_category"
                v-model="selectedPostFilters"
                :options="postFilters"
                select-label=""
                selected-label=""
                deselectLabel=""
                :multiple="true"
                :close-on-select="true"
                group-values="items"
                group-label="name"
                :group-select="false"
                placeholder="Select subfilters"
                label="name"
                track-by="id"
              >
              </multiselect>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect';
import Alert from '@/components/Alert.vue';

import ProjectService from '@/services/ProjectService';
import GroupService from '@/services/GroupService';

import exception from '@/mixins/exception';
import scroll from '@/mixins/scroll';
import alert from '@/mixins/alert';

import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

import { quillEditor } from 'vue-quill-editor';

export default {
  name: 'FormPost',
  mixins: [exception, alert, scroll],
  components: {
    Multiselect,
    Alert,
    quillEditor,
  },
  props: {
    feed: {
      type: Object,
      default: function feedPropDefaultValue() {
        return {};
      },
    },
    categories: {
      type: Array,
    },
    postTypes: {
      type: Array,
    },
    postFilters: {
      type: Array,
    },
    funcCreatePost: {
      type: String,
      default: 'createPostGroup',
    },
  },
  data() {
    return {
      form: {
        text: '',
        text_full: '',
        type: 'Post',
        video: '',
        category_id: undefined,
        post_type_id: undefined,
        project_related_id: undefined,
        filters: [],
        display_name: 1,
        picture: '',
        picture_file: '',
        pdf: '',
        pdf_file: '',
      },
      selectedVideo: '',
      selectedFile: null,
      isParentStrategyProject: false,
      projects: [],
      selectedPostType: null,
      selectedPostFilters: [],
      selectedCategory: [],
      selectedProject: {},
      showInputVideo: false,
      showInputPDF: false,
      showInputImage: false,
      textInputFile: '',
      isBadVideoLink: false,
      isBadFile: false,
      isLoadingFile: false,
      fileFormat: '',
      fileType: '',

      editorOption: {
        placeholder: 'Insert your main text here',
        modules: {
          toolbar: [
            [{ header: 1 }, { header: 2 }],

            ['bold', 'italic', 'underline', 'strike'],
            [{ align: [] }],
            ['blockquote', 'code-block'],

            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],

            [{ color: [] }, { background: [] }],

            ['clean'],
          ],
        },
      },
    };
  },
  created() {
    this.getProjects();
  },
  computed: {
    createPost() {
      return this.funcCreatePost === 'createPostProject'
        ? this.onSavePostProject
        : this.onSavePost;
    },
  },
  methods: {
    async getProjects() {
      let res = null;

      try {
        res = await ProjectService.getProjectsForAttach();

        this.projects = res.data;
      } catch (e) {
        this.projects = [];
        this.handleException(e);
      }
    },
    onChangeFile(event) {
      this.isBadFile = false;
      if (!event.target.files[0].type.includes(this.fileFormat)) {
        this.isBadFile = true;
        return;
      }

      /* eslint-disable prefer-destructuring */
      this.selectedFile = event.target.files[0];
    },
    onRemoveFile(type) {
      this.form[type] = '';
      this.form[`${type}_file`] = '';
    },
    onRemoveVideo() {
      this.selectedVideo = '';
      this.form.video = '';
    },
    async onSavePostProject() {
      try {
        const res = await ProjectService.createFeed(this.$route.params.id, this.form);

        if (res.data.type === 'Error') {
          const errorKeys = Object.keys(res.data.message);

          this.scrollToTop('header_post_form');
          this.showAndHideAlertSuccess(res.data.message[errorKeys[0]][0], 'danger');
          return;
        }

        this.clearForm();
        this.$emit('saved');
        this.$emit('showFilter');
      } catch (e) {
        this.handleException(e);
      }
    },
    async onSavePost() {
      try {
        this.isLoadingFile = true;
        const res = await GroupService.createFeed(this.$route.params.id, this.form);
        this.isLoadingFile = false;
        if (res.data.type === 'Error') {
          const errorKeys = Object.keys(res.data.message);

          this.scrollToTop('header_post_form');
          this.showAndHideAlertSuccess(res.data.message[errorKeys[0]][0], 'danger');
          return;
        }

        this.clearForm();
        this.$emit('saved');
        this.$emit('showFilter');
      } catch (e) {
        this.handleException(e);
      }
    },
    onCancelPost() {
      this.$emit('cancel');
    },
    setFile() {
      if (this.selectedFile?.type !== 'application/pdf' && !this.selectedFile?.type.includes('image/')) {
        this.isBadFile = true;
        return;
      }

      if (this.selectedFile.size >= (1048576 * 5)) {
        this.isBadFile = true;
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(this.selectedFile);

      reader.onload = () => {
        if (this.selectedFile.type === 'application/pdf') {
          this.form.pdf = this.selectedFile.name;
          this.form.pdf_file = reader.result;
        } else if (this.selectedFile.type.includes('image/')) {
          this.form.picture = this.selectedFile.name;
          this.form.picture_file = reader.result;
        }

        this.showInputImage = false;
        this.showInputPDF = false;
      };
    },
    clearForm() {
      this.selectedPostFilters = [];
      this.selectedCategory = [];
      this.selectedProject = {};
      this.selectedPostType = {};

      this.form = {
        text: '',
        text_full: '',
        type: 'Post',
        video: '',
        category_id: undefined,
        post_type_id: undefined,
        project_related_id: undefined,
        filters: [],
        display_name: 1,
        picture: '',
        picture_file: '',
        pdf: '',
        pdf_file: '',
      };
    },
    uploadVideo() {
      const regExp = /^https?:\/\/.+$/ig;

      if (regExp.test(this.selectedVideo)) {
        this.form.video = this.selectedVideo;
        this.showInputVideo = false;
        return;
      }

      this.isBadVideoLink = true;
    },
  },
  watch: {
    feed() {
      if (!this.feed) return;

      this.form = {
        id: this.feed.id,
        text: this.feed.text || '',
        text_full: this.feed.text_full || '',
        type: 'Post',
        video: this.feed.video?.url,
        category_id: this.feed.category.id,
        post_type_id: this.feed.post_type.id,
        project_related_id: this.feed.project_related,
        filters: this.feed.filters.map((feedFilter) => +feedFilter.id),
        display_name: 1,
        picture: this.feed.picture?.filename || '',
        picture_file: this.feed.picture?.url || '',
        pdf: this.feed.pdf?.filename || '',
        pdf_file: this.feed.pdf?.url || '',
      };

      this.selectedPostType = this.feed.post_type || {};
      this.selectedPostFilters = this.feed.filters
        .map((feedFilter) => ({ ...feedFilter, id: +feedFilter.id }));
      this.selectedCategory = this.feed.category || {};

      this.isParentStrategyProject = !!this.feed.project_related?.id;
      this.selectedProject = this.feed.project_related || {};
    },
    'form.files': function formFiles() {
      if (!this.form.files.length) this.$refs.inputFiles.value = '';
    },
    selectedPostFilters() {
      this.form.filters = this.selectedPostFilters.map((postFilter) => postFilter.id);
    },
    selectedPostType() {
      this.form.post_type_id = this.selectedPostType.id;
    },
    selectedCategory() {
      this.form.category_id = this.selectedCategory.id;
    },
    selectedProject() {
      this.form.project_related_id = this.selectedProject.id || '';
    },
    selectedVideo() {
      this.isBadVideoLink = false;
    },
    selectedFile() {
      this.isBadFile = false;
    },
    isParentStrategyProject() {
      if (!this.isParentStrategyProject) this.selectedProject = {};
    },
    showInputVideo() {
      if (!this.showInputVideo) return;

      this.showInputPDF = false;
      this.showInputImage = false;
      this.isBadFile = false;
      this.isBadVideoLink = false;
    },
    showInputPDF() {
      if (!this.showInputPDF) return;

      this.textInputFile = 'Here you can upload PDF file';
      this.fileFormat = 'pdf';
      this.fileType = 'application/pdf';
      this.isBadFile = false;
      this.isBadVideoLink = false;
      this.showInputVideo = false;
      this.showInputImage = false;
    },
    showInputImage() {
      if (!this.showInputImage) return;

      this.textInputFile = 'Here you can upload Image file (gif, jpg, png)';
      this.fileFormat = 'image';
      this.fileType = 'image/*';
      this.isBadFile = false;
      this.isBadVideoLink = false;
      this.showInputVideo = false;
      this.showInputPDF = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn_cancel {
    margin-right: 10px;
    background-color: #ffffff;
    border: 2px solid #ffdd4a;

    &:hover {
      background-color: #ffdd4a;
    }
}

.error_text {
  color: red;
  position: relative;
  top: -20px;
  text-align: left;
  left: 5px;
  width: 100%;
  display: inline-block;
}

.user-post__area {
  width: 100%;
  padding-bottom: 0;
  margin-bottom: 0;
}

.icon-delete {
  cursor:pointer;
}

.search__list {
  margin-top: 10px;
}

::v-deep .ql-container.ql-snow {
  height: 185px;
  border: none;
  border-top: 1px solid #ccc !important;
}

::v-deep .ql-toolbar.ql-snow {
  border: none;
}

::v-deep .multiselect__option--highlight {
  background: #489dd9;
  outline: none;
  color: #fff;
}
::v-deep span.multiselect__option.multiselect__option--highlight::after {
  background: #489dd9;
}
::v-deep .multiselect__tag {
  background: #489dd9;
}
.page-layout {
  background-image: none !important;
}

.select_category {
  max-width: 140px;
  margin-right: 15px;
}

::v-deep .multiselect__tags {
  border-radius: 10px;
  padding-top: 7px;
  overflow: hidden;
  height: 40px;

  .multiselect__tag {
    margin-bottom: 0;
  }
}

::v-deep .multiselect__select:before {
  top: 75%;
  color: #000;
  border-color: #000 transparent transparent;
  border-width: 11px 6px 0;
}

::v-deep .multiselect__placeholder {
  font-size: 14px;
  padding-top: 0;
  color: #000;
}

::v-deep .multiselect__single {
  margin-top: 3px;
  color: #000;
}

.search__category {
  .select_category {
    max-width: 100% !important;
  }

  ::v-deep .dropdown {
    &.btn-group {
      max-width: 100%;

      .btn-filter {
        height: 40px;
      }
    }

    width: 100%;
    max-width: 203px;
    margin-right: 15px;
  }

  ::v-deep .btn-filter {
    font-size: 16px;
    font-weight: 400;
    padding: 9.5px 40px 9.5px 15px;
    border-width: 1px;
    background-color: #fff;
    border: 1px #ced4da solid;
    box-shadow: none;
    overflow: hidden;
  }
}

::v-deep .search__category .btn-filter::after {
    content: '';
    bottom: 7px;
    right: 1px;
    width: 23px;
    background-color: #fff;
    background-position: center;
}

.list-item {
  display: inline-block;
  margin-right: 10px;
}
.list-enter-active, .list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active до версии 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}

span.btn[disabled] {
  background-color: #eee;
  cursor: default;

  .spinner {
    background-color: #eee;
  }
}

span.btn[disabled]:hover {
  color: #000;

  .spinner {
    background-color: #eee;
  }
}

.spinner {
    width: 20px;
    height: 20px;
    display: inline-block;
    background-size: contain;
    background-color: #004360;
    margin-right: 10px;
    transform: rotateZ(-54deg);
    animation: 4s linear 0s infinite alternate move_eye;
}

@keyframes move_eye {
  from { transform: rotateZ(0deg); }
  to { transform: rotateZ(360deg); }
}
</style>
