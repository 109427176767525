<template>
  <div :class="'category-' + index" class="post__list" :style="{border: styles.cardBorder}">
    <div class="post__wrapper">
      <div class="post__item">
        <div v-if="authUserID === feed.user_id" class="post__icon dropright">
          <b-dropdown
            :html="'...'"
            toggle-class="btn-gear"
            menu-class="dropdown-round-box"
            dropright
          >
            <b-dropdown-item
              class="project-setting__item"
              link-class="link_dropdown"
              @click.prevent="onEdit"
            >
              <span class="icon-setting-edit"></span>Edit
            </b-dropdown-item>
            <b-dropdown-item
              class="project-setting__item"
              link-class="link_dropdown"
              @click.prevent="$emit('deletedPost')"
            >
              <span class="icon-setting-bin"></span>Delete
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="post__head">
          <div class="post__avatar">
            <img v-if="feed.avatar" :src="feed.avatar" alt="avatar">
            <img v-else src="@/assets/images/avatar.svg" alt="avatar">
          </div>
          <div class="post__name">
            <h3 v-if="feed.name">{{ feed.name }}</h3>
            <span>{{ feed.created_at }}</span>
            <div class="categories">
              <ul class="post__category categories__list">
                <li :style="{backgroundColor: styles.labelCategoryBackgroundColor}">
                  <span>{{ categoryName }}</span>
                </li>
              </ul>
              <span v-if="unreadFeed"
                    class="message_badge"
                    :class="getClassBadgeLength(unreadFeed.cnt)"
                    title="Unread comments"
              >{{ unreadFeed.cnt }}</span>
            </div>
          </div>
        </div>
        <div class="post__body">
          <div v-html="feed.text" class="post__body-text"></div>
        </div>
        <b-collapse :id="'collapse'+feed.id">
          <div class="post__body">
            <div v-html="feed.text_full" class="post__body-text"></div>
          </div>
        </b-collapse>
        <a
          v-if="feed.text_full"
          v-b-toggle
          :href="'#collapse'+feed.id"
          class="btn_read_more"
          @click.prevent="isOpenReadMore = !isOpenReadMore"
        >{{ textBtnReadMore }}...</a>
        <div>
          <router-link
            v-if="feed.project_related"
            :to="{ name: 'strategyProjectPageView', params: { id: feed.project_related.slug } }"
            class="btn-edit"
            style="display: inline-block; margin: 20px 0;"
            target="_blank"
          >
            Read Strategy Project: {{ feed.project_related.name }}
          </router-link>
        </div>
        <SummaryBlock
          v-if="feed.pdf || feed.video"
          :projectID="+feed.id"
          :text="''"
          :file="feed.pdf ? feed.pdf.url : ''"
          :video_html="feed.video ? feed.video.html : ''"
          :style_color="feed.post_type.color"
          :summaryStyles="styles.summaryBlock"
        />
        <SummaryBlock
          v-if="feed.picture"
          :projectID="+feed.id"
          :text="''"
          :file="feed.picture ? feed.picture.url : ''"
          :video_html="''"
          :style_color="feed.post_type.color"
          :summaryStyles="styles.summaryBlock"
        />
        <div class="post__like">
          <a
            href="#"
            class="action-like"
            @click.prevent="onHandleLike"
          >
            <span class="icon-like--active"></span>
            <span class="action-like-num">{{ feed.cnt_like }}</span>
          </a>
        </div>
      </div>
    </div>
    <CommentsStrategyProject
      v-if="activeComments"
      :comments="comments"
      :postsShow="postsShow"
      :totalPosts="totalPosts"
      :projectID="+feed.id"
      :projectType="feed.type"
      :projectRole="feed.role"
      @addComment="onAddComment"
      @deletePost="onDeleteComment"
      @addPostLike="onAddPostLike"
      @removePostLike="onRemovePostLike"
      @showMorePosts="onShowComments"
    />
  </div>
</template>
<script>
import SummaryBlock from '@/views/pages/StrategyProjectView/Components/SummeryBlock.vue';

import CommentService from '@/services/CommentService';
import PostService from '@/services/PostService';

import CommentsStrategyProject from '@/views/profile/Components/V2/CommentsPost.vue';

import random from '@/mixins/random';
import exception from '@/mixins/exception';
import badge from '@/mixins/badge';

import { BCollapse, BDropdown, BDropdownItem } from 'bootstrap-vue';

export default {
  name: 'CardFeed',
  mixins: [random, exception, badge],
  components: {
    CommentsStrategyProject,
    BDropdown,
    BDropdownItem,
    BCollapse,
    SummaryBlock,
  },
  props: {
    feed: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
    },
    authUserID: {
      type: Number,
    },
    authUserRole: {
      type: String,
      default: 'member',
    },
    unreadFeed: {
      type: Object,
    },
  },
  data() {
    return {
      icons: [
        'icon-pen.svg',
        'icon-gear.svg',
        'icon-group.svg',
      ],

      styles: {
        cardBorder: this.feed.post_type ? `1px solid ${this.feed.post_type.color}` : 'none',
        labelCategoryBackgroundColor: this.feed.post_type
          ? this.feed.post_type.color
          : 'transparent',
        summaryBlock: {
          backgroundColor: 'transparent',
          borderRadius: 0,
          padding: 0,
          border: '1px solid #eee',
        },
      },

      isOpenReadMore: false,

      activeComments: true,
      comments: [],
      postsShow: 0,
      totalPosts: 0,
      page: 0,
    };
  },
  created() {
    this.onShowComments();
  },
  computed: {
    randomIcons() {
      const index = this.icons[this.getRandomKey(0, 2)];
      return index;
    },
    textBtnReadMore() {
      return this.isOpenReadMore ? 'Read less' : 'Read more';
    },
    categoryName() {
      return this.feed.category ? this.feed.category.name : '';
    },
  },
  methods: {
    onHandleLike() {
      if (this.feed.like) {
        this.$eventBus.$emit('removeLike', this.feed.id);
      } else {
        this.$eventBus.$emit('addLike', this.feed.id);
      }
    },
    onEdit() {
      this.$eventBus.$emit('editPost', this.feed);
    },
    async onShowComments() {
      let res = null;
      this.postsShow += 5;
      this.page += 1;

      if (!this.comments.length) {
        this.subscribeToChannel('project', this.setUnreadPostsAndCommentsFromSocket, this.project.id);
      }

      try {
        res = await CommentService.getComments(+this.feed.id, {
          'per-page': 5,
          page: this.page,
        });

        this.comments = [...this.comments, ...res.data];
        this.totalPosts = +res.headers['x-pagination-total-count'];
        this.activeComments = true;
      } catch (e) {
        this.handleException(e);
      }
    },
    onAddComment(comment) {
      this.comments.unshift(comment);
    },
    onDeleteComment(id) {
      this.$eventBus.$emit('onDeleteComment', id);
      this.comments = this.comments.filter((comment) => comment.id !== id);
    },
    async onAddPostLike(id) {
      try {
        await PostService.addLike(id);

        const curComment = this.comments.find((comment) => comment.id === id);
        curComment.like = true;
        curComment.cnt_like += 1;
      } catch (e) {
        console.log(e.response, 'danger');
        this.handleException(e);
      }
    },
    async onRemovePostLike(id) {
      try {
        await PostService.removeLike(id);

        const curComment = this.comments.find((comment) => comment.id === id);
        curComment.like = false;
        curComment.cnt_like -= 1;
      } catch (e) {
        console.log(e.response, 'danger');
        this.handleException(e);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.btn_read_more {
  color: #2295d6;
  font-weight: 600;
}

::v-deep .post__icon .dropdown-toggle {
  color: black;
  font-weight: 400;
}

.project-setting__item {
  text-align: center;
}

::v-deep .dropdown-round-box {
  padding: 0.5rem 0 !important;
}

::v-deep .dropdown-item:hover, .dropdown-item:focus {
  background-color: #ffffff;
}

.feed__btn_delete_post {
  display: block;
  text-align: right;
  padding: 0 30px 5px;
  color: #56a3db;
}

.message_badge {
  &.two {
    width: 30px;
    border-radius: 40%;
  }

  &.three {
    width: 40px;
    border-radius: 40%;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;

  // position: absolute;
  // top: -10px;
  // right: -10px;

  background-color: red;
  border-radius: 50%;

  margin-left: 10px;

  z-index: 1;

  color: #fff;
  font-size: 12px;
  font-weight: 600;

  // @media (max-width: 992px) {
  //   right: -20px;
  // }
}

.categories {
  display: flex;
  align-items: center;
  margin-top: 5px;

  &__list {
    margin-top: 0;
  }
}

.post__list:last-of-type {
  margin-bottom: 0;
}
</style>
