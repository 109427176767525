<template>
  <div>

    <div class="search__form-container">
      <h3>Search</h3>
      <div class="search__form">
        <input
          v-model="searchText"
          type="text"
          @keyup.enter="onChangeText"
        >
        <button class="search__form-btn" @click.prevent="onChangeText">
          <span class="search__form-icon"></span>
        </button>
      </div>
    </div>
    <div class="accordion" id="accordion">
      <div class="card">
        <div class="card-header" id="headingTwo">
          <button class="btn-accordion collapsed" type="button" data-toggle="collapse"
                  data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
            <span>Choose a channel:</span>
          </button>
        </div>
        <div id="collapseTwo" class="collapse show" aria-labelledby="headingTwo"
             data-parent="#accordion" style="">
          <div class="card-body">
            <ul v-if="!categories.length" class="channel__list">
              <span> No filters yet </span>
            </ul>
            <ul v-else class="channel__list">
              <li class="channel__item"
                  v-for="(category, index) in categories"
                  :key="category.id"
                  :class="{collapseditem: index > 9}">
                <span class="category__filter"
                      :class="{isActive: +category.id === 0}"
                      :id="category.name + category.id"
                      @click="selectCategory(category)"
                      style="cursor: pointer">
                  {{ category.name }}
                  <span v-if="+category.id === 0 && categories.length > 10">
                    ({{ categories.length-1 }})
                  </span>
                </span>
                <!--                <ul>-->
                <!--                  <li v-for="item in category.items" :key="item.id"-->
                <!--                      :id="item.name + item.id"-->
                <!--                      class="category__filter"-->
                <!--                      @click="selectCategory(item)" style="cursor: pointer">-->
                <!--                    {{ item.name }}-->
                <!--                  </li>-->
                <!--                </ul>-->
              </li>
            </ul>
            <span v-if="categories.length > 10" class="showmore"
                  @click="showMore">{{ showMoreText }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion" id="accordionExample">
      <div class="card">
        <div class="card-header" id="headingOne">
          <button class="btn-accordion collapsed" type="button" data-toggle="collapse"
                  data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
            <span>Choose post type:</span>
          </button>
        </div>
        <div id="collapseOne" class="collapse" aria-labelledby="headingOne"
             data-parent="#accordionExample" style="">
          <div class="card-body">
            <ul class="channel__list">
              <li class="channel__item post__filter" v-for="post in postTypes" :key="post.id"
                  :id="post.name + post.id"
                  @click="selectType(post)" style="cursor: pointer">{{ post.name }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingThree">
          <button class="btn-accordion collapsed" type="button" data-toggle="collapse"
                  data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
            <span>Choose a subfilter:</span>
          </button>
        </div>
        <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
             data-parent="#accordionExample" style="">
          <div class="card-body">
            <ul class="channel__list">
              <li class="channel__item" v-for="filter in postFilters" :key="filter.id">
                <ul v-if="filter.items">
                  <span class="category__title">{{ filter.name }}:</span>
                  <li v-for="item in filter.items" :key="item.id"
                      :id="item.name + item.id"
                      class="filter__filter"
                      @click="selectFilter(item)" style="cursor: pointer">
                    {{ item.name }}
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import Multiselect from 'vue-multiselect';

import exception from '@/mixins/exception';

export default {
  name: 'FilterPost',
  mixins: [exception],
  components: {
    // Multiselect,
  },
  props: {
    categories: {
      type: Array,
      required: true,
    },
    postTypes: {
      type: Array,
      required: true,
    },
    postFilters: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedPostType: {
        id: 0,
        name: 'All Types',
      },
      selectedPostFilters: [],
      selectedCategory: {
        id: 0,
        name: 'All Channels',
        items: [],
      },
      searchText: '',
      showMoreText: 'Show more',
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
    selectCategory(item) {
      const activeItem = document.getElementById(`${item.name + item.id}`);
      const categories = document.getElementsByClassName('category__filter');
      Array.prototype.forEach.call(categories, (category) => {
        category.classList.remove('isActive');
      });
      activeItem.classList.add('isActive');
      this.selectedCategory = item;
      this.$emit('changeCategory', this.selectedCategory);
    },
    selectType(post) {
      const activePost = document.getElementById(`${post.name + post.id}`);
      const posts = document.getElementsByClassName('post__filter');
      Array.prototype.forEach.call(posts, (item) => {
        item.classList.remove('isActive');
      });
      activePost.classList.add('isActive');
      this.selectedPostType = post;
      this.$emit('changeType', this.selectedPostType);
    },
    selectFilter(item) {
      const currentItem = document.getElementById(`${item.name + item.id}`);
      if (!currentItem.classList.contains('isActive')) {
        currentItem.classList.add('isActive');
        this.selectedPostFilters.push(item);
      } else {
        let filterArray = [];
        currentItem.classList.remove('isActive');
        filterArray = this.selectedPostFilters
          .filter((element) => element !== item);
        this.selectedPostFilters = [...filterArray];
      }
      this.$emit('changeFilters', this.selectedPostFilters);
    },
    onChangeText() {
      this.$emit('changeText', this.searchText);
    },
    showMore() {
      const collapsed = document.getElementsByClassName('collapseditem');
      Array.prototype.forEach.call(collapsed, (collaps) => {
        if (!collaps.classList.contains('showitem')) {
          collaps.classList.add('showitem');
          this.showMoreText = 'Show less';
        } else {
          collaps.classList.remove('showitem');
          this.showMoreText = 'Show more';
        }
      });
    },
  },
  watch: {
    // selectedPostType() {
    //   this.$emit('changeType', this.selectedPostType);
    // },
    // selectedCategory() {
    //   this.$emit('changeCategory', this.selectedCategory);
    // },
    // selectedPostFilters() {
    //   this.$emit('changeFilters', this.selectedPostFilters);
    // },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .multiselect__option--highlight {
  background: #489dd9;
  outline: none;
  color: #fff;
}

::v-deep span.multiselect__option.multiselect__option--highlight::after {
  background: #489dd9;
}

::v-deep .multiselect__tag {
  background: #489dd9;
}

.select_category {
  max-width: 140px;
  margin-right: 15px;
}

::v-deep .multiselect__tags {
  border-radius: 10px;
  padding-top: 7px;
  overflow: hidden;
  height: 40px;

  .multiselect__tag {
    margin-bottom: 0;
  }
}

::v-deep .multiselect__select:before {
  top: 75%;
  color: #000;
  border-color: #000 transparent transparent;
  border-width: 11px 6px 0;
}

::v-deep .multiselect__placeholder {
  font-size: 14px;
  padding-top: 0;
  color: #000;
}

::v-deep .multiselect__single {
  margin-top: 3px;
  color: #000;
}

.search__category {
  .select_category {
    max-width: 100% !important;
  }

  ::v-deep .dropdown {
    &.btn-group {
      max-width: 100%;

      .btn-filter {
        height: 40px;
      }
    }

    width: 100%;
    max-width: 203px;
    margin-right: 15px;
  }

  ::v-deep .btn-filter {
    font-size: 16px;
    font-weight: 400;
    padding: 9.5px 40px 9.5px 15px;
    border-width: 1px;
    background-color: #fff;
    border: 1px #ced4da solid;
    box-shadow: none;
    overflow: hidden;
  }
}

::v-deep .search__category .btn-filter::after {
  content: '';
  bottom: 7px;
  right: 1px;
  width: 23px;
  background-color: #fff;
  background-position: center;
}

.category__title {
  //background: #ededed;
  font-weight: bold;
}

.isActive {
  color: #1d9dd9;
}

.collapseditem {
  //display: none;
  white-space: nowrap;
  overflow: hidden;
  height: 0;
  min-height: 0;
  //display: none;
  transition: 0.5s;
}

.channel__item.collapseditem {
  margin: 0;
}

.showmore {
  cursor: pointer;
  color: #2295d6;
  font-weight: 600;
  margin-top: 10px;
}
.showitem {
  height: auto;
  min-height: 20px;
}
.channel__item.collapseditem.showitem {
  margin: 5px 0;
}
</style>
