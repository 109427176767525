<template>
  <div class="accFeed__wrapper" style="background: #fff;">
    <div class="accFeed__sidebar">
      <div v-if="showBtnAddPost" class="user-post__area">
        <button
          class="btn btn-primary"
          style="margin-bottom: 50px; margin-bottom: 0;"
          @click.prevent="onShowModalAddPost"
        >
          Add Post
        </button>
      </div>
      <FilterPost
        v-if="isFilter"
        :categories="categories"
        :postTypes="postTypes"
        :postFilters="postFilters"
        @changeType="onChangeType"
        @changeCategory="onChangeCategory"
        @changeFilters="onChangeFilters"
        @changeText="onChangeText"
      />
    </div>
    <div v-if="!posts.length" class="accFeed__empty">
      No comments yet
    </div>
    <div class="post__area">
      <template v-if="posts.length">
        <CardFeed
          v-for="(feed, index) in posts"
          :key="feed.created_at + feed.text + feed.id"
          :feed="feed"
          :index="index+1"
          :authUserID="me.id"
          :authUserRole="authUserRole"
          :unreadFeed="unreadFeeds.find((f) => +f.id === +feed.id)"
          @deletedPost="onDeletedPost(feed)"
        />
      </template>
      <ul class="feed__list">
      </ul>
    </div>
    <Modal
      @close="hideModal(modalDeletePost)"
      :id=modalDeletePost
      title=""
      buttonTextOk="Delete"
      buttonVariantOk="danger"
      :showButtonCancel="true"
      @cancel="hideModal(modalDeletePost)"
      @ok="deletePost"
    >
      <p>Are you sure?</p>
    </Modal>
    <Modal
      :id=modalAddPost
      title=""
      buttonTextOk="Save"
      buttonVariantOk="danger"
      :showButtonCancel="true"
      :showIcon="false"
      :isFooter="false"
      style="overflow: auto; padding-top: 0;"
      @cancel="hideModal(modalAddPost)"
      @close="hideModal(modalAddPost)"
    >
      <FormPost
        :feed="selectedFeed"
        :categories="categories.slice(1)"
        :postTypes="postTypes.slice(1)"
        :postFilters="postFilters"
        :funcCreatePost="funcCreatePost"
        @cancel="hideModal(modalAddPost)"
        @saved="onCloseModalAddPost"
        @showFilter="$emit('showFilter')"
      />
    </Modal>
    <Modal
      @close="hideModal(modalDeleteComment)"
      :id=modalDeleteComment
      title=""
      buttonTextOk="Delete"
      buttonVariantOk="danger"
      :showButtonCancel="true"
      @cancel="hideModal(modalDeleteComment)"
      @ok="deleteComment"
    >
      <p>Are you sure?</p>
    </Modal>
  </div>
</template>
<script>
import CommentService from '@/services/CommentService';
import PostService from '@/services/PostService';
import DictionaryService from '@/services/DictionaryService';

import me from '@/mixins/me';
import modal from '@/mixins/modal';
import exception from '@/mixins/exception';

import CardFeed from '@/views/profile/Components/V2/CardFeed.vue';
import Modal from '@/components/Modal.vue';
import FormPost from '@/components/FormPost.vue';
import FilterPost from '@/components/FilterPostV2.vue';

import moment from 'moment';

export default {
  name: 'PostsContainer',
  mixins: [me, exception, modal],
  components: {
    Modal,
    CardFeed,
    FormPost,
    FilterPost,
  },
  props: {
    channelId: {
      type: [String, Number],
      default: '',
    },
    posts: {
      type: Array,
      required: true,
    },
    headers: {
      type: Object,
      required: true,
    },
    showBtnAddPost: {
      type: Boolean,
      default: true,
    },
    authUserRole: {
      type: String,
      required: true,
    },
    isFilter: {
      type: Boolean,
      default: false,
    },
    funcCreatePost: {
      type: String,
    },
    unreadFeeds: {
      type: [Array, Object],
    },
  },
  data() {
    return {
      selectedFeed: {},
      deleteFeed: {},
      postText: '',
      modalDeletePost: 'modalDeletePost',
      modalAddPost: 'modalAddPost',
      modalDeleteComment: 'modalDeleteComment',

      deleteCommentId: 0,
      postParams: {
        page: this.$route.query.page || 1,
        'per-page': 10,
        postType: undefined,
        postCategory: undefined,
        postFilter: undefined,
        text: undefined,
      },

      // categories: [{
      //   id: 0,
      //   name: 'All Categories',
      //   items: [{
      //     id: 0,
      //     name: 'All Categories',
      //   }],
      // }],
      categories: [{
        id: 0,
        name: 'All Channels',
      }],
      postTypes: [{
        id: 0,
        name: 'All Types',
      }],
      channel: [],
      postFilters: [],
    };
  },
  async created() {
    this.getPostCategories();
    this.getPostTypes();
    this.getPostFilters();

    this.$eventBus.$on('addLike', this.onAddLike);
    this.$eventBus.$on('removeLike', this.onRemoveLike);
    this.$eventBus.$on('editPost', this.onEditPost);
    this.$eventBus.$on('onDeleteComment', this.onDeleteComment);
  },
  methods: {
    // async getPostCategories() {
    //   let res = null;
    //
    //   try {
    //     res = await DictionaryService.postCategories();
    //     this.categories = [...this.categories, ...res.data];
    //   } catch (e) {
    //     this.categories = [];
    //     this.handleException(e);
    //   }
    // },
    async getPostCategories() {
      console.log(this.channelId);
      let res = null;
      if (this.channelId !== '') {
        try {
          res = await DictionaryService.postIdCategories(this.channelId);
          this.categories = [...this.categories, ...res.data];
        } catch (e) {
          this.categories = [];
          this.handleException(e);
        }
      } else {
        try {
          res = await DictionaryService.getChannelsCategories();
          this.channel = [...res.data];
          this.channel.forEach(async (channel) => {
            try {
              res = await DictionaryService.postIdCategories(channel.id);
              this.categories = [...this.categories, ...res.data];
            } catch (e) {
              this.categories = [];
              this.handleException(e);
            }
          });
        } catch (e) {
          this.categories = [];
          this.handleException(e);
        }
      }
    },
    async getPostTypes() {
      let res = null;

      try {
        res = await DictionaryService.postTypes();
        this.postTypes = [...this.postTypes, ...res.data];
      } catch (e) {
        this.postTypes = [];
        this.handleException(e);
      }
    },
    async getPostFilters() {
      let res = null;

      try {
        res = await DictionaryService.postFilters();
        this.postFilters = res.data;
      } catch (e) {
        this.postFilters = [];
        this.handleException(e);
      }
    },
    onChangeType(postType) {
      this.postParams.page = 1;
      this.postParams.postType = postType.id;
      if (!postType.id) delete this.postParams.postType;
      this.$emit('changePostParams', this.postParams);
      this.$emit('getPosts');
    },
    onChangeCategory(postCategory) {
      this.postParams.page = 1;
      this.postParams.postCategory = postCategory.id;
      if (!postCategory.id) delete this.postParams.postCategory;
      this.$emit('changePostParams', this.postParams);
      this.$emit('getPosts');
    },
    onChangeFilters(postFilters) {
      this.postParams.page = 1;
      this.postParams.postFilter = postFilters.map((filter) => filter.id).join(',');
      if (!postFilters.length) delete this.postParams.postFilter;
      this.$emit('changePostParams', this.postParams);
      this.$emit('getPosts');
    },
    onChangeText(postText) {
      this.postParams.page = 1;
      this.postParams.text = postText;
      if (!postText) delete this.postParams.text;
      this.$emit('changePostParams', this.postParams);
      this.$emit('getPosts');
    },
    async onDeletedPost(feed) {
      this.deleteFeed = feed;
      this.showModal(this.modalDeletePost);
    },
    onShowModalAddPost() {
      this.showModal(this.modalAddPost);
    },
    onCloseModalAddPost() {
      this.postParams.page = 1;
      this.$emit('changePostParams', this.postParams);
      this.$emit('getPosts');

      setTimeout(() => {
        this.hideModal(this.modalAddPost);
      }, 0);
    },
    async onAddLike(feedID) {
      try {
        await PostService.addLike(feedID);
        const feedUpdate = this.posts.find((feed) => feed.id === feedID);

        feedUpdate.like = true;
        feedUpdate.cnt_like += 1;
      } catch (e) {
        this.showAndHideAlertSuccess(e.response.data.message, 'danger');
        this.handleException(e);
      }
    },
    async onRemoveLike(feedID) {
      try {
        await PostService.removeLike(feedID);
        const feedUpdate = this.posts.find((feed) => feed.id === feedID);

        feedUpdate.like = false;
        feedUpdate.cnt_like -= 1;
      } catch (e) {
        this.showAndHideAlertSuccess(e.response.data.message, 'danger');
        this.handleException(e);
      }
    },
    onEditPost(feed) {
      this.selectedFeed = feed;
      this.showModal(this.modalAddPost);
    },
    onDeleteComment(id) {
      this.deleteCommentId = id;

      this.showModal(this.modalDeleteComment);
    },
    deleteComment() {
      try {
        CommentService.deleteComment(this.deleteCommentId);

        this.hideModal(this.modalDeleteComment);
      } catch (e) {
        this.handleException(e);
      }
    },
    moment(date) {
      return moment(date);
    },
    async deletePost() {
      try {
        await PostService.delete(this.deleteFeed.id);
        this.$emit('deletePost', this.deleteFeed.id);
        this.hideModal(this.modalDeletePost);
      } catch (e) {
        this.handleException(e);
      }
    },
  },
  watch: {
    headers() {
      console.log('watcher');
      // this.setPagination(this.headers);
    },
    postParams() {
      console.log('watcher');
    },
  },
  beforeDestroy() {
    this.$eventBus.$off('addLike', this.onAddLike);
    this.$eventBus.$off('removeLike', this.onRemoveLike);
    this.$eventBus.$off('editPost', this.onEditPost);
  },
};
</script>
<style lang="scss" scoped>
.container_dialog {
  margin-bottom: 80px;
}

.container_dialog:last-child {
  margin-bottom: 0;
}

.list-complete-item {
  transition: all 1s;
  display: inline-block;
  margin-right: 10px;
}

.list-complete-enter, .list-complete-leave-to
  /* .list-complete-leave-active до версии 2.1.8 */
{
  opacity: 0;
  transform: translateY(30px);
}

.list-complete-leave-active {
  position: absolute;
}

.filter {
  display: block;

  .dropdown {
    max-width: 200px;
  }
}

::v-deep .btn-filter {
  text-align: left;
  font-weight: 400;
  font-size: 16px;

  border: none;

  padding: 13.5px 40px 13.5px 15px;
}

.btn_send[disabled] {
  background-color: #EEEEEE;
  color: #FFFFFF;
  cursor: default;
}

.search__list {
  margin-top: 10px;
}

::v-deep .multiselect__option--highlight {
  background: #489dd9;
  outline: none;
  color: #fff;
}

::v-deep span.multiselect__option.multiselect__option--highlight::after {
  background: #489dd9;
}

::v-deep .multiselect__tag {
  background: #489dd9;
}

.page-layout {
  background-image: none !important;
}

.select_category {
  max-width: 140px;
  margin-right: 15px;
}

::v-deep .multiselect__tags {
  border-radius: 10px;
  padding-top: 7px;
  overflow: hidden;

  .multiselect__tag {
    margin-bottom: 0;
  }
}

::v-deep .multiselect__select:before {
  top: 75%;
  color: #000;
  border-color: #000 transparent transparent;
  border-width: 11px 6px 0;
}

::v-deep .multiselect__placeholder {
  font-size: 14px;
  padding-top: 0;
  color: #000;
}

::v-deep .multiselect__single {
  margin-top: 3px;
  color: #000;
}

.search__category {
  .select_category {
    max-width: 100% !important;
  }

  ::v-deep .dropdown {
    &.btn-group {
      max-width: 100%;

      .btn-filter {
        height: 40px;
      }
    }

    width: 100%;
    max-width: 203px;
    margin-right: 15px;
  }

  ::v-deep .btn-filter {
    font-size: 16px;
    font-weight: 400;
    padding: 9.5px 40px 9.5px 15px;
    border-width: 1px;
    background-color: #fff;
    border: 1px #ced4da solid;
    box-shadow: none;
    overflow: hidden;
  }
}

::v-deep .search__category .btn-filter::after {
  content: '';
  bottom: 7px;
  right: 1px;
  width: 23px;
  background-color: #fff;
  background-position: center;
}

.list-item {
  display: inline-block;
  margin-right: 10px;
}

.list-enter-active, .list-leave-active {
  transition: all 1s;
}

.list-enter, .list-leave-to /* .list-leave-active до версии 2.1.8 */
{
  opacity: 0;
  transform: translateY(30px);
}

@media (max-width: 768px) {
  .accFeed__wrapper {
    grid-template-columns: 1fr;
    height: auto;
  }
}
</style>
