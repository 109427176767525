<template>
  <div>
    <div v-if="text || file" class="summary" :style="summaryStyles">
      <div class="summary__body">
        <p v-if="text" v-html="text.replaceAll('\n', '<br>').replaceAll(' ', '&nbsp;')"></p>
        <object v-if="file && file.includes('pdf')" type="application/pdf"
            :data="file"
            :height="file.includes('pdf') ? '720' : '370'"
            width="100%">
        </object>
        <img v-else-if="file" :src="file">
      </div>
    </div>
    <div v-if="video_html" class="summary" style="background-color: #9BA7B3" :style="summaryStyles">
      <div v-if="false" class="summary__title">
        Summary Title - Top recomendation
      </div>
      <div class="summary__body">
        <div v-if="video_html" v-html="video_html"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SummaryBlock',
  components: {},
  mixins: [],
  props: {
    text: {
      type: String,
      default: '',
    },
    file: {
      type: String,
      default: '',
    },
    video_html: {
      type: String,
      default: '',
    },
    style_color: {
      type: String,
    },
    projectID: {
      type: Number,
    },
    summaryStyles: {
      type: Object,
      default: function summarySt() {
        return null;
      },
    },
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {},
};
</script>
<style lang="scss">
object {
  width: 100%;
}

.summary {
  width: 100%;
  padding: 40px 40px 45px 40px;
}
.summary__body{
  line-height: 0;
}

.summary__body p {
  word-wrap: break-word;
}

.slider-items__item.active {
  background-color: #1d5d99;
  color: #fff;
}
</style>
